define(['$console', 'app', 'productImageCarousel', 'productImageZoom', 'productDescription', '$window', 'accessibleModalHelper'], ($console, app, productImageCarousel, productImageZoom, productDescription, $window, accessibleModalHelper) => {

  const productInformationModal = () => {
    const component = {};

    const _config = {
      attrib: {
        popUpSelect: 'data-popUp-select-id',
        productInfoModal: 'data-open-productinfo-modal',
        popupError: 'data-popup-error'
      },
      selectors: {
        productInfoIcon: '[data-product-info]',
        productInfoModal: '[data-open-productinfo-modal]',
        closeModalButton: '[data-close-productinfomodal]',
        productDescription: '[data-accordion-container]',
        accordionHeadClass: 'productInformationModal_accordionHeader',
        accordionBody: '[data-accordion-body]',
        noCarousel: '[data-show-carousel=false]',
        insertModal: '[data-insert-modal]',
        productInfoModalTitle: '.productInformationModal_modalTitle',
      },
      childComponents: {
        productDescription: '[data-component=productDescription]',
        productImageCarousel: '[data-component=productImageCarousel]',
        productImageZoom: '[data-component=productImageZoom]'
      },
      classNames: {
        showProductInfoModal: 'productInformationModal_productInfo',
        productInfo: 'productInformationModal_productInfo',
        accordionShowClass: 'productInformationModal_accordion-show',
        accordionHideClass: 'productInformationModal_accordion-hide',
        popUpLock: 'js-popup-lock',
        closeModalClass: 'productInformationModal_close',
        narrowModal: 'productInformationModal_modal-noImageCarousel'
      },
      stringTemplatePath: '/components/productInformationModal/productInformationModal'
    };

    const _init = element => {
      component.element = element;
      if (!component.element || component.element instanceof HTMLElement === false) {
        $console.error('productInformationModal.init was called without the expected HTMLElement');
        return;
      }
      component.bindIcons();

      component.getElements();
      return component;
    };

    const _getElements = () => {
      component.productInfoModal = document.querySelector(component.config.selectors.productInfoModal);
      component.closeModalButton = document.querySelector(component.config.selectors.closeModalButton);
      component.attachListeners();
    };

    const _bindIcons = () => {
      const infoIcon = document.querySelectorAll(component.config.selectors.productInfoIcon);
      if (!infoIcon.length) {
        return;
      }
      Array.from(infoIcon).map(el => el.addEventListener('click', component.showModal, false));
    };

    const _attachListeners = () => {
      component.productInfoModal && component.productInfoModal.addEventListener('click', component.modalClose, false);
      component.closeModalButton && component.closeModalButton.addEventListener('click', component.hideModal, false);
    };

    const _showModal = (e) => {
      e.preventDefault();
      e.stopPropagation();

      component.popUpSelectId = e.currentTarget.parentElement.getAttribute(component.config.attrib.popUpSelect);
      app.publish('tracking/record', 'productInformationModal', 'Clicked on Product Info', 'product Id', component.popUpSelectId);

      component.ajaxInfoPopUp(component.popUpSelectId);

      component.closeSubscription = app.subscribe('modal/close', component.hideModal);
    };

    const _modalClose = (e) => {
      if (e.target.classList.contains(component.config.classNames.showProductInfoModal)) {
        component.hideModal();
      }
    };

    const _hideModal = () => {
      component.productInfoModal.classList.remove(component.config.classNames.showProductInfoModal);
      document.documentElement.classList.remove(component.config.classNames.popUpLock);
      document.body.style.top = '';

      const removeModal = document.querySelector(component.config.selectors.productInfoModal);
      removeModal.parentNode.removeChild(removeModal);

      app.element.scrollTo(component.prevScrollTop);

      if (component.closeSubscription) {
        component.closeSubscription.remove();
        component.closeSubscription = null;
      }

      if (component.reloadSubscription) {
        component.reloadSubscription.remove();
        component.reloadSubscription = null;
      }

      component.accessibleModalHelper && component.accessibleModalHelper.close();
    };


    const _ajaxInfoPopUp = (productId) => {

      component.newContainer = document.createElement('div');
      component.newContainer.className = component.config.classNames.productInfo;
      component.newContainer.setAttribute(component.config.attrib.productInfoModal, '');
      component.newContainer.innerHTML = `<div class="productInformationModal_modal" data-insert-modal>
            <div class="productInformationModal_spinner"></div>
        </div>`;
      document.documentElement.appendChild(component.newContainer);
      document.documentElement.classList.add(component.config.classNames.popUpLock);
      component.prevScrollTop = $window.pageYOffset;
      document.body.style.top = `${-component.prevScrollTop}px`;
      component.getElements();

      app.ajax.get({
        url: `/${productId}.productPopUp?stringTemplatePath=${component.config.stringTemplatePath}`,
        dataType: 'JSON',
        success: component.infoPopUpSuccess,
        error: component.infoPopUpError
      });
    };

    const _infoPopUpSuccess = (response) => {
      const insertModalEl = document.querySelector(component.config.selectors.insertModal);
      insertModalEl.innerHTML = response;

      component.getElements();

      const noCarouselEl = document.querySelector(component.config.selectors.noCarousel);
      noCarouselEl && insertModalEl.classList.add(component.config.classNames.narrowModal);

      app.publish('tracking/record', 'productInformationModal', 'Viewed Product Info', 'viewed product', component.popUpSelectId);
      component.initialisePopUpComponents();

      const modalHeading = component.productInfoModal.querySelector(component.config.selectors.productInfoModalTitle);
      component.accessibleModalHelper = new accessibleModalHelper(component.productInfoModal, component.hideModal, modalHeading);
      component.reloadSubscription = app.subscribe('modal/reloadAccessibleModalHelper',
        target => component.accessibleModalHelper.reload(component.productInfoModal, target),
      );
    };

    const _infoPopUpError = () => {
      const popUpError = component.element.getAttribute(component.config.attrib.popupError);

      const insertModalEl = document.querySelector(component.config.selectors.insertModal);
      insertModalEl.innerHTML = `<div class="productInformationModal_popupError">${popUpError}</div>`;
    };

    const _initialisePopUpComponents = () => {
      const productDescriptionEl = document.querySelector(component.config.childComponents.productDescription);
      productDescriptionEl && new productDescription().init(productDescriptionEl);

      const productImageCarouselEl = document.querySelector(component.config.childComponents.productImageCarousel);
      productImageCarouselEl && new productImageCarousel().init(productImageCarouselEl);

      const productImageZoomEl = document.querySelector(component.config.childComponents.productImageZoom);
      productImageZoomEl && new productImageZoom().init(productImageZoomEl);
    };

    component.config = _config;
    component.init = _init;
    component.getElements = _getElements;
    component.bindIcons = _bindIcons;
    component.attachListeners = _attachListeners;
    component.showModal = _showModal;
    component.hideModal = _hideModal;
    component.modalClose = _modalClose;
    component.ajaxInfoPopUp = _ajaxInfoPopUp;
    component.infoPopUpSuccess = _infoPopUpSuccess;
    component.infoPopUpError = _infoPopUpError;
    component.initialisePopUpComponents = _initialisePopUpComponents;

    return component;
  };

  return productInformationModal;

});

